import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class SizeChartProvider extends HttpRequest {
  getSizeChartByProductId (id) {
    this.setHeader(getAuthToken())
    return this.get(`size-chart/${id}`)
  }

  createSizeChart (sizeChartForm) {
    this.setHeader(getAuthToken())
    return this.post('size-chart', sizeChartForm)
  }

  updateSizeChart (sizeChartForm) {
    this.setHeader(getAuthToken())
    return this.put(`size-chart/${sizeChartForm.id}`, sizeChartForm)
  }

  deleteSizeChart (id) {
    this.setHeader(getAuthToken())
    return this.delete(`size-chart/${id}`)
  }
}

export default SizeChartProvider
